import $axios from './index'
//首页-题库一二级类列表
export function firstOrSecondList(data){
	const url = '/api/app/v1/test_paper/type/firstOrSecondList';
	return $axios.post(url,data);
}
//首页-题库二级类列表
export function secondOrList(data){
	const url = '/api/app/v1/test_paper/type/second_list';
	return $axios.post(url,data);
}
//首页-章节/专项练习列表
export function exerciseList(data){
	const url = '/api/app/v1/test_paper/exercise/list';
	return $axios.post(url,data);
}
//首页-根据二级类Id进入题库首页
export function test_paper(data){
	const url = '/api/app/v1/test_paper/home';
	return $axios.post(url,data);
}
//首页-章节/专项练习-题解析列表
export function analysis_list(data){
	const url = '/api/app/v1/test_paper/exercise/subject/analysisList';
	return $axios.post(url,data);
}

//首页-章节/专项练习-题解析列表 -- new
export function analysis_new(data){
	const url = '/api/app/v1/test_paper/exercise/subject/analysis';
	return $axios.post(url,data);
}


//首页-章节/专项练习-答题获取题列表
export function subject_list(data){
	const url = '/api/app/v1/test_paper/exercise/subjectList';
	return $axios.post(url,data);
}
//首页-章节/专项练习-保存答题
export function save_subject(data){
	const url = '/api/app/v1/test_paper/exercise/save_subject';
	return $axios.post(url,data);
}
//首页-章节/专项练习-交卷or答题报告
export function assignment_exercise(data){
	const url = '/api/app/v1/test_paper/exercise/assignment_paper';
	return $axios.post(url,data);
}
//添加/删除标记
export function add_sign(data){
	const url = '/api/app/v1/test_paper/add_sign';
	return $axios.post(url,data);
}
//添加收藏
export function add_collect(data){
	const url = '/api/app/v1/subject_collect/add_collect';
	return $axios.post(url,data);
}
//删除收藏
export function deleted_collect(data){
	const url = '/api/app/v1/subject_collect/deleted_collect';
	return $axios.post(url,data);
}
//删除错题
export function del_error(data){
	const url = '/api/app/v1/error_subject/del/error/subject';
	return $axios.post(url,data);
}


//历年/模拟/考前试卷列表
export function test_paperList(data){
	const url = '/api/app/v1/test_paper/paper/list';
	return $axios.post(url,data);
}
//历年/模拟/考前试卷-题解析列表
export function subject_analysisList(data){
	const url = '/api/app/v1/test_paper/paper/subject/analysisList';
	return $axios.post(url,data);
}



//历年/模拟/考前试卷-题解析列表 -- new 
export function analysisList_new(data){
	const url = '/api/app/v1/test_paper/paper/subject/problem/analysisList';
	return $axios.post(url,data);
}

//历年/模拟/考前试卷-获取题
export function subjectPaperList(data){
	const url = '/api/app/v1/test_paper/paper/subjectList';
	return $axios.post(url,data);
}
//历年/模拟/考前试卷-保存答题
export function savePaper_subject(data){
	const url = '/api/app/v1/test_paper/paper/save_subject';
	return $axios.post(url,data);
}
//历年/模拟/考前试卷-交卷or答题报告
export function assignment_paper(data){
	const url = '/api/app/v1/test_paper/paper/assignment_paper';
	return $axios.post(url,data);
}



// 做题记录

//章节列表
export function exercise_subject_chapter(data){
	const url = '/api/app/v1/exercise_subject_eecord/chapter/list';
	return $axios.post(url,data);
}
//试卷列表
export function exercise_subject_paper(data){
	const url = '/api/app/v1/exercise_subject_eecord/paper/list';
	return $axios.post(url,data);
}

// 习题收藏

//习题收藏列表
export function collect_list(data){
	const url = '/api/app/v1/subject_collect/collect_list';
	return $axios.post(url,data);
}
//题-解析
export function subject_analysis(data){
	const url = '/api/app/v1/subject_collect/subject_analysis';
	return $axios.post(url,data);
}

//题-解析
export function questionStatistics(data){
	const url = '/api/app/v1/test_paper/paper/subject/problem/QuestionStatistics';
	return $axios.post(url,data);
}
//添加收藏
export function subject_addCollect(data){
	const url = '/api/app/v1/subject_collect/add_collect';
	return $axios.post(url,data);
}
//删除收藏
export function subject_delCollect(data){
	const url = '/api/app/v1/subject_collect/deleted_collect';
	return $axios.post(url,data);
}



// 错题本
//章节列表
export function error_subject_chapterList(data){
	const url = '/api/app/v1/error_subject/chapter/list';
	return $axios.post(url,data);
}
//试卷列表
export function error_subject_paperList(data){
	const url = '/api/app/v1/error_subject/paper/list';
	return $axios.post(url,data);
}
//章节-答题获取题列表(重做)
export function error_exercise_subjectList(data){
	const url = '/api/app/v1/error_subject/exercise/subjectList';
	return $axios.post(url,data);
}
//试卷-答题获取题列表(重做)
export function error_paper_subjectList(data){
	const url = '/api/app/v1/error_subject/paper/subjectList';
	return $axios.post(url,data);
}